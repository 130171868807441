import './teaser-selection.scss';

import AjaxLoad from '../../utilities/js/ajax-load';
import { tryParseJSON } from '../../utilities/js/helper';
import { Selector } from '../selector/selector';
import { Slider } from '../slider/slider';

class TeaserSelection {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-teaser-selection',
            tileMarkup: '<div class="teaser-selection__item swiper-slide" data-teaserselection-item><section class="tile-teaser"><a href="###url###"><figure class="tile-teaser__image"><img src="###image###" alt="" /></figure><span class="tile-teaser__title">###title###</span></a></section></div>'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$teaserSelection = element;
        this.$selector = this.$teaserSelection.querySelector('[data-selector]');
    }

    initialize () {
        this.setEvents();

        if (this.$selector) {
            this.initSelector(this.$teaserSelection);
        }

        this.initSlider(this.$teaserSelection);
    }

    initSlider (slider) {
        this.slider = new Slider(slider, {
            initAttr: this.settings.initAttr,
            prevnext: false,
            calculateHeight: false,
            breakpoints: {
                1023: {
                    slidesPerView: 'auto'
                },
                767: {
                    slidesPerView: 'auto'
                }
            }
        });
    }

    // inits selector module
    initSelector (selector) {
        this.selector = new Selector(selector, {
            onChanged: (v) => {
                this.$teaserSelection.dispatchEvent(new Event('submit'));
            }
        });
    }

    setEvents () {
        this.$teaserSelection.addEventListener('submit', (e) => {
            const $form = e.target;
            const uri = $form.getAttribute('action') + '?selector_kitchen=' + $form.querySelector('[name="selector_kitchen"]:checked').value;
            const formData = new FormData($form);

            new AjaxLoad(uri, { // eslint-disable-line
                requestData: formData,
                requestMethod: 'GET',
                onSuccess: (result) => {
                    const JSONresponse = tryParseJSON(result);
                    this.slider.slider.removeAllSlides();

                    JSONresponse.forEach((value) => {
                        const content = value;
                        console.log('AJAX content', content);
                        let newMarkup = this.settings.tileMarkup;

                        newMarkup = newMarkup.replace('###url###', content.uri);
                        newMarkup = newMarkup.replace('###image###', content.image);
                        newMarkup = newMarkup.replace('###title###', content.title);

                        this.slider.slider.appendSlide(newMarkup);
                    });

                    if (JSONresponse.success === false) {
                        console.log('error');
                    }
                }
            });

            e.preventDefault();
        });
    }
}

export { TeaserSelection };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$TeaserSelection = $context.querySelectorAll('[data-teaser-selection="root"]');
        for (let i = 0; i < $$TeaserSelection.length; i++) {
            const $TeaserSelection = new TeaserSelection($$TeaserSelection[i]);
            $TeaserSelection.initialize();
        }
    }
});
